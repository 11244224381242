export const ResponseBookHotel = {
    id:'',
    room: {
      name:'',
      accommodation: {
        name:'',
        cover:''
      }
    },
    status:'',
    check_in:'',
    check_out:'',
    count:'',
    cost:'',
    final_price:'',
    first_name:'',
    last_name:'',
    mobile:'',
    email:'',
    nationality:'',
    passport_number:'',
    country:'',
    city:'',
    address:'',
    postalCode:'',
    transaction:'',
    book_response:{
      id:'',
      hotel_request_id:'',
      description:'',
      expire_time:'',
      cancellation:'',
      price:'',
      is_day:''
    }
  };