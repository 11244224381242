/*eslint react/jsx-no-comment-textnodes: "off"*/
import React from "react";
import {Route, Switch,Redirect} from "react-router-dom";
import asyncComponent from "./components/AsyncComponent";
import AppliedRoute from "./components/AppliedRoute";

// import AsyncFrontendPage from './containers/Frontend'

// const AsyncFrontendPage = asyncComponent(() => import('./containers/Frontend'))
const AsyncLoginPage = asyncComponent(() => import('./containers/Login'))
const AsyncLocalGuidePage = asyncComponent(() => import('./containers/LocalGuide'))
const AsyncOauthCallback = asyncComponent(() => import('./containers/Login'))
const AsyncForbiddenCallback = asyncComponent(() => import('./containers/Forbidden'))
const AsyncAdminCallback = asyncComponent(() => import('./containers/Admin'))
const AsyncAdminLogin = asyncComponent(() => import('./containers/admin/login'))
const AsyncWhyIran = asyncComponent(() => import('./containers/frontend/why-iran'))

export default ({childProps}) =>
    <Switch>
        //#region Frontend
            <Route exact path="/">
                   <Redirect to="/admin/login" />
            </Route>

            {/*<AppliedRoute*/}
            {/*path="/"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/why-iran"*/}
            {/*exact*/}
            {/*component={AsyncWhyIran}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        {/*<AppliedRoute*/}
            {/*path="/payment-callback"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/hotel-payment-callback"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        {/*<AppliedRoute*/}
            {/*path="/instant-book-payment-callback"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        {/*<AppliedRoute*/}
            {/*path="/specials/:slug/:showcase_id"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/destinations"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/trackingLG/:token"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/localguides/:id"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/continue-book"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/localguides"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/sights"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/sights/:id/:slug?"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/online-booking/iran-hotels/:city/:id/:slug"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}


        {/*<AppliedRoute*/}
            {/*path="/hotel-payment-callback"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        {/*<AppliedRoute*/}
            {/*path="/online-travel-services/:id/:slug"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/online-travel-services"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/tracking-online-travel-service/:token"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/online-travel-services-payment-callback"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/online-booking/iran-hotels/:city"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/online-booking/iran-hotels-destinations"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        {/*<AppliedRoute*/}
            {/*path="/online-booking/iran-hotels"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/routes"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/route-trips"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        {/*<AppliedRoute*/}
            {/*path="/iran-travel-insurance"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/iran-visa-application"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/iran-visa-application-form"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/payment-visa-callback"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        {/*<AppliedRoute*/}
            {/*path="/apply-for-iran-travel-insurance"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        {/*<AppliedRoute*/}
            {/*path="/payment-insurance-callback"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}


        {/*<AppliedRoute*/}
            {/*path="/iran-tours"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/iran-tours/:id/:slug"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}


        {/*<AppliedRoute*/}
            {/*path="/request-book"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/request-book-hotel"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}


        {/*<AppliedRoute*/}
            {/*path="/things-to-do-in-iran"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}


        {/*<AppliedRoute*/}
            {/*path="/things-to-do-in-iran/:id/:slug"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}


        {/*<AppliedRoute*/}
            {/*path="/online-booking/iran-bus-tickets"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/online-booking/iran-bus-tickets/book"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}


        {/*<AppliedRoute*/}
            {/*path="/bus-ticket-callback"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        {/*<AppliedRoute*/}
            {/*path="/online-booking/iran-bus-tickets-search/:items"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}


        {/*<AppliedRoute*/}
            {/*path="/routes/:id/:slug?"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/search"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/faq"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/about-us"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/tracking/:token"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/tracking-book-hotel/:token"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/iranroute"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/contact-us"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/team"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/our-services"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/policies"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/terms"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/ethics-for-tourism"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/iran-visa"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        {/*<AppliedRoute*/}
            {/*path="/get-app"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/customersuport"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/destinations/:slug/:cityId/"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/provinces/:provinceId/:slug?"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        {/*//#profile*/}


        {/*<AppliedRoute*/}
            {/*path="/profile"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/user/email_verify"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}


        {/*//#endregion*/}

        {/*//#region Other*/}
        {/*<AppliedRoute*/}
            {/*path="/login"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        <AppliedRoute
            path="/403"
            exact
            component={AsyncForbiddenCallback}
            props={childProps}
        />
        //#endregion

        //#region Account
        <AppliedRoute
            path="/account"
            exact
            component={AsyncLocalGuidePage}
            props={childProps}
        />
        <AppliedRoute
            path="/account-home"
            exact
            component={AsyncLocalGuidePage}
            props={childProps}
        />
        <AppliedRoute
            path="/account-profile"
            exact
            component={AsyncLocalGuidePage}
            props={childProps}
        />
        <AppliedRoute
            path="/account-vehicle"
            exact
            component={AsyncLocalGuidePage}
            props={childProps}
        />
        <AppliedRoute
            path="/oauth/google/callback"
            exact
            component={AsyncOauthCallback}
            props={childProps}
        />
        //#endregion

        //#region Admin
        <AppliedRoute
            path="/admin"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin-tours"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-things-to-do"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-tours-edit/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-localguiderequests"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-localguiderequests-details/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-hotel-requests"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-requests-history"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-request/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-requests"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-requests-history"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-request-details/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin-tours-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-tours-items/:tour_id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-tours-videos/:tour_id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-tours-photo/:tour_id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-tours-packages/:tour_id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-tour-packages-wizard/:package_id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-tour-packages-services/:package_id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-tour-themes/:tourId"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-tour-packages-check-availability/:package_id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin-hotel"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-room-add/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-room-edit/:id/:room_id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-room-photo/:id/:room_id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-room-facilities/:id/:room_id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-photo/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-facilities"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-facility/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-rooms/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-room-packages/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-edit/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-hotel-items/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin-services/"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-extra-service"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-banner"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-additional-service"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-additional-service-edit/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-additional-service-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-additional-requests"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-additional-request-details/:token"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin-dashboard"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        //#region Admin Sights
        <AppliedRoute
            path="/admin-sight"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-nearby"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-sight-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/edit"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-sight-theme"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/theme"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-sight-season"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/season"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-sight-cost"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/cost"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-sight-type"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/type"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-sight-transportation"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/transportation"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-sight-highlight"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/highlight"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/highlights"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/metadata"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-sight-metadata"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/photo"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/degree-photo"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/sight/:sightId/restaurant"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin/members"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/image-slider"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/showcase"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin/login"
            exact
            component={AsyncAdminLogin}
            props={childProps}
        />

        //#endregion

        //#region Admin Routes


        <AppliedRoute
            path="/admin-insurance-requests"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-insurance-request-details/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-visa-requests"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-visa-request-details/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-bus-requests"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-bus-request/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin/route-trip/:routeId/transportation"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/route-trip/:routeId/sights"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/route-trip/:routeId/routes"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/route-trip/:routeId/photo"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/route-trip/:routeId/cities"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/route-trip/:routeId/metadata"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin/route-trip/:routeId/theme"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin/route-trip/:routeId/edit"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin-route-trips"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        {/*<AppliedRoute*/}
            {/*path="/route-trips"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}
        {/*<AppliedRoute*/}
            {/*path="/route-trips/:id/:slug"*/}
            {/*exact*/}
            {/*component={AsyncFrontendPage}*/}
            {/*props={childProps}*/}
        {/*/>*/}

        <AppliedRoute
            path="/admin-route-trip-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin-route"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-route-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/route/:routeId/edit"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/route/:routeId/theme"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-route-theme-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-route-season"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-route-season-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/route/:routeId/meta"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-route-cost-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/route/:routeId/time"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-route-transportation-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/route/:routeId/place"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-route-highlight-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-users"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-list"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-list-role/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        //#endregion

        //#region Admin Themes
        <AppliedRoute
            path="/admin/themes"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/themes/add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/themes/:themeId/edit"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        //#endregion

        //#region Admin Top Routes
        <AppliedRoute
            path="/admin/top-routes"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/top-routes/add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        //#endregion

        //#region Admin Provinces
        <AppliedRoute
            path="/admin/provinces"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/provinces/add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/provinces/:provinceId/edit"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        //#endregion

        //#region Admin Cities
        <AppliedRoute
            path="/admin/cities"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/cities/add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin/cities/:cityId/edit"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-insurance-price"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-insurance-terms"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin-visa-countries"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-visa-terms"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-visa-term/steps/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin-translator"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-options"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-user-add"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-user-avatar/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />
        <AppliedRoute
            path="/admin-user-workinfo/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />

        <AppliedRoute
            path="/admin-user-experiences/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        <AppliedRoute
            path="/admin-user-carinfo/:id"
            exact
            component={AsyncAdminCallback}
            props={childProps}
        />


        //#endregion

        <AppliedRoute
            props={childProps}
            component={AsyncAdminCallback}
        />
    </Switch>
