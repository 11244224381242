const prefix = 'UTIL/'

export const STATE_GET_BEGIN = prefix + 'STATE_GET_BEGIN'
export const STATE_GET_SUCCESS = prefix + 'STATE_GET_SUCCESS'
export const STATE_GET_FAILED = prefix + 'STATE_GET_FAILED'
export const STATE_PROVINCE_GET_BEGIN = prefix + 'STATE_PROVINCE_GET_BEGIN'
export const STATE_PROVINCE_GET_SUCCESS = prefix + 'STATE_PROVINCE_GET_SUCCESS'
export const STATE_PROVINCE_GET_FAILED = prefix + 'STATE_PROVINCE_GET_FAILED'


export const SET = prefix + 'SET'
export const RESET = prefix + 'RESET'

