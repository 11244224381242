import {
    CONTACT_GET_BEGIN,
    CONTACT_GET_SUCCESS,
    CONTACT_GET_FAILED,
    CONTACT_UPDATE_BEGIN,
    CONTACT_UPDATE_SUCCESS,
    CONTACT_UPDATE_FAILED
} from '../types/personal'
const initialState = {
    data: null,
    message: ''
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CONTACT_GET_BEGIN: {
            return {
                loading: true,
                data: null,
                message: 'Loging...'
            }
        }
        case CONTACT_GET_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                message: action.message,
            }
        }
        case CONTACT_GET_FAILED: {
            return {
                loading: false,
                data: null,
                error: action.error,
                message: action.error.message,
            }
        }
        case CONTACT_UPDATE_BEGIN: {
            return {
                loading: true,
                data: null,
                message: 'Loging...'
            }
        }
        case CONTACT_UPDATE_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                message: action.message,
            }
        }
        case CONTACT_UPDATE_FAILED: {
            return {
                loading: false,
                data: null,
                error: action.error,
                message: action.error.message,
            }
        }
        default:
            return state
    }
}