import {combineReducers} from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr'
import {loadingBarReducer} from 'react-redux-loading-bar'

import user from './user';
import extra_services from './extra_services';
import hotel from './hotel'
import profile from './profile';
import state from './state';
import language from './language';
import contact from './contact';
import personalInfo from './personal';
import localguide from './localguide';
import sight from './sight';
import intl from './intl';
import AdditionalService from './additional_service';

export default function createRootReducer({apolloClient}) {
    return combineReducers({
        // comment this for apollo 2.x.x
        // apollo: apolloClient.reducer(),
        user,
        personalInfo,
        profile,
        state,
        localguide,
        sight,
        contact,
        language,
        intl,
        hotel,
        extra_services,
        AdditionalService,
        toastr: toastrReducer,
        loadingBar: loadingBarReducer,
    });
}