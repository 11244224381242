import {
    LANGUAGE_GET_BEGIN,
    LANGUAGE_GET_SUCCESS,
    LANGUAGE_GET_FAILED,
} from '../types/language'
const initialState = {
    data: {}
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LANGUAGE_GET_BEGIN: {
            return {
                loading: true,
                data: action.data
            }
        }
        case LANGUAGE_GET_SUCCESS: {
            return {
                loading: false,
                data: action.data
            }
        }
        case LANGUAGE_GET_FAILED: {
            return {
                loading: false,
                data: action.data,
                error: action.error
            }
        }
        default:
            return state
    }
}