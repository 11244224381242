

const prefix = 'SIGHT/'

export const SIGHT_GET_BEGIN = prefix + 'SIGHT_GET_BEGIN'
export const SIGHT_GET_SUCCESS = prefix + 'SIGHT_GET_SUCCESS'
export const SIGHT_GET_FAILED = prefix + 'SIGHT_GET_FAILED'

export const SET = prefix + 'SET'
export const RESET = prefix + 'RESET'

