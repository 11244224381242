export const hotelConstants={
    GETBYID_SUCCESS: 'HOTEL_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'HOTEL_GETBYID_FAILURE',
    GETBYID_REQUEST: 'HOTEL_GETBYID_REQUEST',
    GETMAIN_REQUEST:'HOTEL_GETMAIN_REQUEST',
    GETMAIN_SUCCESS:'HOTEL_GETMAIN_SUCCESS',
    GETMAIN_FAILURE:'HOTEL_GETMAIN_FAILURE',
    GETNEARBY_SUCCESS: 'HOTEL_GETNEARBY_SUCCESS',
    GETNEARBY_FAILURE: 'HOTEL_GETNEARBY_FAILURE',
    GETNEARBY_REQUEST: 'HOTEL_GETNEARBY_REQUEST',
    GETALL_SUCCESS: 'HOTEL_GETALL_SUCCESS',
    GETALL_FAILURE: 'HOTEL_GETALL_FAILURE',
    GETALL_REQUEST: 'HOTEL_GETALL_REQUEST',
    UPDATEREQUEST_SUCCESS: 'UPDATE_REQUEST_SUCCESS',
    UPDATEREQUEST_FAILURE: 'UPDATE_REQUEST_FAILURE',
    UPDATEREQUEST_REQUEST: 'UPDATE_REQUEST_REQUEST',
    TRACKREQUEST_SUCCESS: 'TRACK_REQUEST_SUCCESS',
    TRACKREQUEST_FAILURE: 'TRACK_REQUEST_FAILURE',
    TRACKREQUEST_REQUEST: 'TRACK_REQUEST_REQUEST',
    UPDATETRANSACTION_SUCCESS: 'UPDATE_TRANSACTION_SUCCESS',
    UPDATETRANSACTION_FAILURE: 'UPDATE_TRANSACTION_FAILURE',
    UPDATETRANSACTION_REQUEST: 'UPDATE_TRANSACTION_REQUEST',
  
    PAYMENT_SUCCESS: 'UPDATE_ PAYMENT_SUCCESS',
    PAYMENT_FAILURE: 'UPDATE_ PAYMENT_FAILURE',
    PAYMENT_REQUEST: 'UPDATE_ PAYMENT_REQUEST',
    GETTOPCITIES_SUCCESS: 'CITY_GETALL_SUCCESS',
    GETTOPCITIES_FAILURE: 'CITY_GETALL__FAILURE',
    GETTOPCITIES_REQUEST: 'CITY_GETALL_REQUEST',
    GETLIST_REQUEST:'HOTEL_GETLIST_REQUEST',
    GETLIST_SUCCESS:'HOTEL_GETLIST_SUCCESS',
    GETLIST_FAILURE:'HOTEL_GETLIST_FAILURE',
    GETPOPULAR_REQUEST:'HOTEL_GETPOPULAR_REQUEST',
    GETPOPULAR_SUCCESS:'HOTEL_GETPOPULAR_SUCCESS',
    GETPOPULAR_FAILURE:'HOTEL_GETPOPULAR_FAILURE'

}
export const filterType={
    HOTEL:"hotel",
    HOSTEL:"hostel",
    BOUTIQUE:"boutique hotel",
    GUESTHOUSE:"guesthouse_ecolodge",
    LUXURY:"luxury",
    BUSINESS:"business",
    ECONOMY:"economy"
}

export const pagination = {
    page: 1,
    perpage: 15
}

