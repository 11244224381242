let apiUrl = 'https://api.iranroute.com/';
// let apiUrl = 'http://localhost:8000/';
let secretKey = 'Wio3Nr2XFBWPVEIAOiV2E5U2FoWdE8oNvz7EGloC'; //aAJJorqPZrTbyZhocXGTJ7v2WlLB52yTboHz5l9D
let googleClientKey = '1009932117953-i66uh9njtf7b6gk92fe4754nkji7s5m4.apps.googleusercontent.com';
let googleMapKey = 'AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg';
let clientId = '4';
if (process.env.NODE_ENV === 'production') {
    apiUrl = 'https://api.iranroute.com/';
    secretKey = 'Wio3Nr2XFBWPVEIAOiV2E5U2FoWdE8oNvz7EGloC'
}
export {
    apiUrl,
    secretKey,
    clientId,
    googleClientKey,
    googleMapKey
}
// Header always set Access-Control-Allow-Origin "*"
// Header always set Access-Control-Allow-Headers "authorization,content-type"
// Header always set Access-Control-Allow-Methods "GET, OPTIONS, PUT, DELETE"
//
// RewriteEngine On
// RewriteCond %{REQUEST_METHOD} OPTIONS
// RewriteRule ^(.*)$ $1 [R=200,L]
