import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  REGISTER_BEGIN
} from '../types/user'
const initialState = {
  data: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_BEGIN: {
      return {
        loading: true,
        data: null
      }
    }
    case LOGIN_SUCCESS: {
      return {
        loading: false,
        data: action.data
      }
    }
    case LOGIN_FAILED: {
      return {
        loading: false,
        data: null,
        error: action.error
      }
    }
    case REGISTER_BEGIN: {
      return {
        loading: true,
        data: null
      }
    }
    case REGISTER_SUCCESS: {
      return {
        loading: false,
        data: action.data
      }
    }
    case REGISTER_FAILED: {
      return {
        loading: false,
        data: null,
        error: action.error
      }
    }
    default:
      return state
  }
}