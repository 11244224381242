import {serviceConstants} from '../types/services'

const initialState = {
    loading: false,
    services: [],
    error: '',

}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case serviceConstants.GET_ALL_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case serviceConstants.GET_ALL_SUCCESS: {
            return {
                ...state,
                loading: false,
                services: action.payload.services
            }
        }
        case serviceConstants.GET_ALL_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        default:
            return state
    }
}