import {AdditionalServiceConstants} from '../types/additional_service'
import {hotelConstants} from "../types/hotel";
import {ResponseBookHotel} from "../data/viewModels/ResponseBookHotel";

const initialState = {
    service: [],
    services: [],
    services_list: [],
    update: [],
    trackingData: [],
    paymentLink: '',
    paymentData: [],
    loading: false,
    error: "",
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case AdditionalServiceConstants.GET_BY_ID_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }

        case AdditionalServiceConstants.GET_BY_ID_SUCCESS: {

            return {
                ...state,
                loading: false,
                service: action.payload.service,

            }
        }
        case AdditionalServiceConstants.GET_BY_ID_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                service: {}
            }
        }
        case AdditionalServiceConstants.UPDATE_REQUEST_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }

        case AdditionalServiceConstants.UPDATE_REQUEST_SUCCESS: {

            return {
                ...state,
                loading: false,
                update: action.payload.update,

            }
        }
        case AdditionalServiceConstants.UPDATE_REQUEST_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                update: {}
            }
        }
        case AdditionalServiceConstants.TRACK_REQUEST_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }

        case AdditionalServiceConstants.TRACK_REQUEST_SUCCESS: {

            return {
                ...state,
                loading: false,
                trackingData: action.payload,

            }
        }
        case AdditionalServiceConstants.TRACK_REQUEST_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                trackingData: {}
            }
        }
        case AdditionalServiceConstants.UPDATE_TRANSACTION_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case AdditionalServiceConstants.UPDATE_TRANSACTION_SUCCESS: {
            return {
                ...state,
                trackingData: action.payload,
                paymentLink: action.payload.payment_link,
            }
        }
        case AdditionalServiceConstants.UPDATE_TRANSACTION_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case AdditionalServiceConstants.GET_ALL_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case AdditionalServiceConstants.GET_ALL_SUCCESS: {
            return {
                ...state,
                loading: false,
                services_list: action.payload,
            }
        }
        case AdditionalServiceConstants.GET_ALL_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        default:
            return state
    }
}