import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Routes from "../Routes";
import * as jQuery from 'jquery'
import LoadingBar from 'react-redux-loading-bar'

class App extends Component {
  constructor(props) {
    super(props);
    global.jQuery = jQuery;
    global.$ = jQuery;
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = event => {
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }



  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      client: this.props.client,
      dispatch: this.props.dispatch,
      history: this.props.history,
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App">
      <LoadingBar scope="login" className="loading"/>
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default withRouter(App);
