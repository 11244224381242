import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory'
import { apiUrl } from '../config';
import { RetryLink } from 'apollo-link-retry';
// import { from } from 'apollo-link';

const uri = apiUrl + 'graphql';
const uriAdmin = apiUrl + 'graphql/admin';
// const token = 'Bearer ' + localStorage.getItem('access_token');
const cache = new InMemoryCache({addTypename: false}); //window.__APOLLO_STATE__
const link = new RetryLink().split(
  (operation) => operation.variables.admin === true,
  new HttpLink({ uri: uriAdmin,
      headers: {Authorization: (() => 'Bearer ' + localStorage.getItem('access_token_admin'))()}
  }),
    new HttpLink({ uri: uri, headers: { Authorization: (() => 'Bearer ' + localStorage.getItem('access_token'))() }})
);

// const otherMiddleware = new ApolloLink((operation, forward) => {
//   // add the authorization to the headers
//   operation.setContext(({ headers = {} }) => ({
//     headers: {
//       ...headers,
//       'Access-Control-Allow-Origin': '*',
//     } 
//   }));

//   return forward(operation);
// })


const client = new ApolloClient({
  // link: from([
  //   otherMiddleware,
  //   link
  // ]),
  link,
  cache
});

export default function createApolloClient() {
  return client;
}