import {
    PERSONAL_GET_BEGIN,
    PERSONAL_GET_SUCCESS,
    PERSONAL_GET_FAILED,
} from '../types/personal'
const initialState = {
    data: null
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PERSONAL_GET_BEGIN: {
            return {
                loading: true,
                data: null
            }
        }
        case PERSONAL_GET_SUCCESS: {
            return {
                loading: false,
                data: action.data
            }
        }
        case PERSONAL_GET_FAILED: {
            return {
                loading: false,
                data: null,
                error: action.error
            }
        }        
        default:
            return state
    }
}