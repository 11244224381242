export const AdditionalServiceConstants = {
    GET_BY_ID_SUCCESS: 'ADDITIONAL_SERVICE_GET_BY_ID_SUCCESS',
    GET_BY_ID_FAILURE: 'ADDITIONAL_SERVICE_GET_BY_ID_FAILURE',
    GET_BY_ID_REQUEST: 'ADDITIONAL_SERVICE_GET_BY_ID_REQUEST',

    GET_ALL_SUCCESS: 'ADDITIONAL_SERVICE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'ADDITIONAL_SERVICE_GET_ALL_FAILURE',
    GET_ALL_REQUEST: 'ADDITIONAL_SERVICE_GET_ALL_REQUEST',

    UPDATE_REQUEST_SUCCESS: 'ADDITIONAL_SERVICE_UPDATE_REQUEST_SUCCESS',
    UPDATE_REQUEST_FAILURE: 'ADDITIONAL_SERVICE_UPDATE_REQUEST_FAILURE',
    UPDATE_REQUEST_REQUEST: 'ADDITIONAL_SERVICE_UPDATE_REQUEST_REQUEST',

    TRACK_REQUEST_REQUEST: 'TRACK_REQUEST_REQUEST',
    TRACK_REQUEST_SUCCESS: 'TRACK_REQUEST_SUCCESS',
    TRACK_REQUEST_FAILURE: 'TRACK_REQUEST_FAILURE',

    UPDATE_TRANSACTION_SUCCESS: 'UPDATE_TRANSACTION_SUCCESS',
    UPDATE_TRANSACTION_FAILURE: 'UPDATE_TRANSACTION_FAILURE',
    UPDATE_TRANSACTION_REQUEST: 'UPDATE_TRANSACTION_REQUEST',


}