import { hotelConstants } from '../types/hotel'
import { ResponseBookHotel } from '../data/viewModels/ResponseBookHotel'
const initialState = {
    isrecommended: true,
    loading: false,
    cities: [],
    hotelInfo: [],
    hotelList: [],
    banner: {},
    recommendedList: [],
    error: '',
    destination: '',
    nearbies: [],
    totalPages: 0, perPage: 15, page: 1, total: 0,
    token: '',
    nationalities: [],
    trackingData: ResponseBookHotel,
    paymentLink: '',
    paymentData: ResponseBookHotel,
    isMore: false,
    minprice: '',
    maxprice: '',
    filters: {
        destinations: [],
        pricePerNight: "",
        star: [],
        populated: false,
        recommended: false,
        type: [],
        class: [],
        price: {
            min: '',
            max: ''
        }
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case hotelConstants.GETBYID_REQUEST:
        case hotelConstants.GETMAIN_REQUEST:
        case hotelConstants.GETLIST_REQUEST:
            {
                return {
                    ...state,
                    banner: {},
                    loading: true,
                    isrecommended: true
                }
            }
        case hotelConstants.GETMAIN_SUCCESS: {
            return {
                ...state,
                loading: false,
                cities: action.payload.cities,
                hotelList: action.payload.hotels,
                banner: action.payload.banner,
                isrecommended: false
            }
        }
        case hotelConstants.GETBYID_SUCCESS: {
            return {
                ...state,
                loading: false,
                hotelInfo: action.payload.GetAccommodation,
                nationalities: action.payload.GetNationalities

            }
        }
        case hotelConstants.GETBYID_FAILURE:
        case hotelConstants.GETMAIN_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                isrecommended: false,
                recommendedList:[],
                hotelList:[]
            }
        }
        case hotelConstants.GETNEARBY_REQUEST: {
            return {
                ...state,
                loading: true

            }
        }
        case hotelConstants.GETNEARBY_SUCCESS: {
            return {
                ...state,
                nearbies: action.payload,
                loading: false


            }
        }
        case hotelConstants.GETNEARBY_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        case hotelConstants.GETPOPULAR_REQUEST: {
            return {
                ...state,

                loading: true,


            }
        }
        case hotelConstants.GETALL_REQUEST: {
            return {
                ...state,

                loading: true,
                isMore: action.payload.isMore,
                hotelList: action.payload.hotels


            }
        }
        case hotelConstants.GETALL_SUCCESS: {
            return {
                ...state,
                totalPages: action.payload.totalPages,
                page: action.payload.page,
                perPage: action.payload.perPage,
                loading: false,
                total: action.payload.total,
                hotelList: action.payload.hotels,
                filters: action.payload.filters,
                minprice: action.payload.minprice,
                maxprice: action.payload.maxprice

            }
        }
        case hotelConstants.GETPOPULAR_SUCCESS: {
            return {
                ...state,
                totalPages: action.payload.totalPages,
                page: action.payload.page,
                perPage: action.payload.perPage,
                loading: false,
                total: action.payload.total,
                hotelList: action.payload.hotels,
                filters: action.payload.filters,
                cities: action.payload.cities
            }
        }
        case hotelConstants.GETLIST_SUCCESS: {
            return {
                ...state,
                totalPages: action.payload.totalPages,
                page: action.payload.page,
                perPage: action.payload.perPage,
                loading: false,
                total: action.payload.total,
                hotelList: action.payload.hotels,
                isrecommended: false,
                filters: {
                    ...action.payload.filters, price: {
                        min: action.payload.minprice,
                        max: action.payload.maxprice
                    }
                },
                recommendedList: action.payload.recommended,
                banner: action.payload.banner,
                minprice: action.payload.minprice,
                maxprice: action.payload.maxprice

            }
        }
        case hotelConstants.GETALL_FAILURE:
        case hotelConstants.GETLIST_FAILURE:
        case hotelConstants.GETPOPULAR_FAILURE:
            {
                return {
                    ...state,
                    loading: false,
                    error: action.payload.error,
                    isrecommended: false,
                    recommendedList:[],
                    hotelList:[]
                }
            }
        case hotelConstants.UPDATEREQUEST_REQUEST:
        case hotelConstants.GETTOPCITIES_REQUEST: {
            return {
                ...state,

                loading: true

            }
        }
        case hotelConstants.UPDATEREQUEST_SUCCESS: {
            return {
                ...state,
                token: action.payload.token,
                loading: false

            }
        }
        case hotelConstants.UPDATEREQUEST_FAILURE:
        case hotelConstants.GETTOPCITIES_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case hotelConstants.GETTOPCITIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                cities: action.payload.cities,
                filters: {
                    ...state.filters,
                    destinations: action.payload.destination
                }
            }
        }
        case hotelConstants.TRACKREQUEST_REQUEST: {
            return {
                ...state,
                error: '',
                loading: true,

            }
        }
        case hotelConstants.TRACKREQUEST_SUCCESS: {
            return {
                ...state,
                trackingData: Object.assign({}, ResponseBookHotel, action.payload),
                loading: false
            }
        }
        case hotelConstants.TRACKREQUEST_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case hotelConstants.UPDATETRANSACTION_REQUEST: {
            return {
                ...state,
                loading: true,
                trackingData: ResponseBookHotel

            }
        }
        case hotelConstants.UPDATETRANSACTION_SUCCESS: {
            return {
                ...state,
                paymentLink: action.payload.payment_link,
                loading: false,
                trackingData: ResponseBookHotel
            }
        }
        case hotelConstants.UPDATETRANSACTION_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case hotelConstants.PAYMENT_REQUEST: {
            return {
                ...state,
                loading: true,

            }
        }
        case hotelConstants.PAYMENT_SUCCESS: {
            return {
                ...state,
                paymentData: Object.assign({}, ResponseBookHotel, action.payload),
                loading: false
            }
        }
        case hotelConstants.PAYMENT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }

        default:
            return state
    }
}