const prefix = 'USER/'

export const LOGIN_BEGIN = prefix + 'LOGIN_BEGIN'
export const LOGIN_SUCCESS = prefix + 'LOGIN_SUCCESS'
export const LOGIN_FAILED = prefix + 'LOGIN_FAILED'
export const REGISTER_BEGIN = prefix + 'REGISTER_BEGIN'
export const REGISTER_SUCCESS = prefix + 'REGISTER_SUCCESS'
export const REGISTER_FAILED = prefix + 'REGISTER_FAILED'


export const SET = prefix + 'SET'
export const RESET = prefix + 'RESET'

