import React from "react";
import {Redirect, Route} from "react-router-dom";

export default ({ component: C, props: cProps, ...rest }) => {
    const token = localStorage.getItem('access_token_admin');
    let auth =  false;
    if (token){
        auth = true;
    }

    if (rest.path === "/admin/login"){
        if (auth){
            //redirect to dashboard
            return <Redirect
                to={{
                    pathname: "/admin-dashboard",
                    state: {from: rest.location}
                }}
            />
        }
    }

    return <Route {...rest} render={props => <C {...props} {...cProps} />}/>;
};

