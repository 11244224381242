/*eslint no-use-before-define: "off"*/

import React from 'react'
import ReactDOM from 'react-dom'

// import { Provider } from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import createApolloClient from './core/createApolloClient.client';
import {ApolloProvider} from 'react-apollo';
import createFetch from './createFetch';
import history from './history';
import {apiUrl} from './config';
import configureStore from './store'
import './index.css'

import './assets/frontend2/css/bootstrap.min.css'
import './assets/frontend2/css/font-awesome.min.css'
import './assets/frontend2/css/md-font.min.css'
/*  select2 must import before style.css due to css order and overriding */
import './assets/frontend2/css/select2.min.css'
import 'pretty-checkbox/dist/pretty-checkbox.min.css'
/* -------------------------------------------------------------------- */
import './assets/frontend2/css/style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from './containers/App'
// import registerServiceWorker from './registerServiceWorker'
import HttpsRedirect from 'react-https-redirect';

// Let the reducers handle initial state
const initialState = {}
const apolloClient = createApolloClient();
const fetch = createFetch(fetch, {
    baseUrl: apiUrl,
});

const store = configureStore(initialState, {
    apolloClient,
    fetch,
    history,
})

if (module.hot) {
    module.hot.accept();
}

ReactDOM.render(
    <ApolloProvider store={store} client={apolloClient}>
        <BrowserRouter>
            <App client={apolloClient} dispatch={store.dispatch}/>
        </BrowserRouter>
    </ApolloProvider>
    , document.getElementById('root')
)


// registerServiceWorker()
