import React, {Component} from "react";
import Loadable from 'react-loadable';
import {ScaleLoader} from 'react-spinners/';
import {css} from '@emotion/core';

export default function asyncComponent(importComponent, childProps = {}) {
    class AsyncComponent extends Component {
        constructor(props) {
            super(props);

            this.state = {
                component: null
            };
        }

        async componentDidMount() {
            // const { default: component } = await importComponent();
            const component = Loadable({
                loader: () => importComponent(),
                loading: MyLoadingComponent
            });
            this.setState({
                component: component
            });
        }

        render() {
            const C = this.state.component;

            return C ? <C {...this.props} {...childProps} /> : null;
        }
    }

    return AsyncComponent;
}
const MyLoadingComponent = ({isLoading, error}) => {

    const override = css`
    display: block;
    margin: 25px auto;
    border-color: red;
`;
    if (isLoading) {
        return (<div
            style={{
                textAlign: "center",
                margin: "20% auto"

            }}
            className='sweet-loading'>
            <ScaleLoader
                css={override}
                size={30}
                color={'#33cccc'}
                loading={isLoading}
            />
            <h6> Please Wait...
            </h6>
        </div>)
    }
    // Handle the error state
    else if (error) {
        return <div>Error !</div>;
    }
    else {
        return null;
    }
};